//не более 2 знаков после запятой

export const validationSum = (sum) => {
  if (Math.floor(+sum) !== +sum) {
    return sum.toString().split(".")[1].length <= 2
  }
  return true
};

export const ReplaceDotToComma = (num) => {
  return num.toFixed(2).replace(/\./g, ',')
}