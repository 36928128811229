import {createSlice} from "@reduxjs/toolkit";

import api, {dataEncryption, delLoginCookie, getCookie, setLoginCookie} from "utils/api";
import {authConstants} from "constants/authConstants";
import swal from "sweetalert";
import jwtDecode from "jwt-decode";


export const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuth: false,
    right: "",
    message: "",
    login: "",
    password: "",
    authInProcess: true,
  },
  reducers: {
    setAuth: (state, {payload}) => {
      return {...state, ...payload};
    },
  },
});

export const {setAuth} = authSlice.actions;

export const CheckIsAuth = () => (dispatch) => {
  const setUserData = () => {
    let newState;
    if (getCookie(authConstants.accessToken) && getCookie(authConstants.accessToken) !== "undefined") {
      const AccessToken = getCookie(authConstants.accessToken);
      const hasPrivilege = getCookie(authConstants.hasPrivilege);
      if (AccessToken) {
        let data = jwtDecode(AccessToken);
        newState = {
          isAuth: true,
          userId: data.userId,
          right: data.AUTHORITIES_KEY[0].authority,
          login: data.sub || "",
          name: data?.userName || data.sub || "",
          password: data.sub || "",
          authInProcess: false,
          hasPrivilege: !(hasPrivilege === "false")
        }
      }
    } else {
      newState = {
        isAuth: false,
        right: "",
        login: "",
        password: "",
        authInProcess: false
      }
    }
    return newState
  }

  return new Promise((resolve) => {
    const newState = setUserData();
    return resolve(dispatch(setLoadAuth(newState)));
  });

}

export const login = (login, password) => (dispatch) => {
  const encryptedLogin = dataEncryption(login.trim());
  const encryptedPSW = dataEncryption(password.trim());
  dispatch(setLoadAuth({authInProcess: true}));
  api("/login", {
    method: "POST",
    data: {login: encryptedLogin, password: encryptedPSW},
  })
    .then((auth) => {
      dispatch(setLoadAuth({
        isAuth: false,
        right: "",
        message: "",
        login: "",
        password: "",
        authInProcess: true,
      }));

      if (auth && auth.status === 403) {
        return swal({
          icon: "warning",
          text: "Your account has been deactivated!"
        })
      }
      if (auth) {
        const AccessToken = auth.accessToken;
        setLoginCookie(auth);
        if (AccessToken) {
          let data = jwtDecode(AccessToken);
          let newState = {
            isAuth: true,
            userId: data.userId,
            right: data.AUTHORITIES_KEY[0].authority,
            login: data.sub || "",
            name: data?.userName || data.sub || "",
            password: data.sub || "",
            authInProcess: false,
            hasPrivilege: auth.hasPrivilege,
          }
          dispatch(setLoadAuth(newState));
        }
      }
    });
};

export const logout = (dispatch) => {
  let userId = getCookie(authConstants.userId);
  const currentRefreshToken = getCookie(authConstants.refreshToken);
  const currentAccessToken = getCookie(authConstants.accessToken);
  const data = {
    accessToken: currentAccessToken,
    refreshToken: currentRefreshToken
  }

  dispatch(setLoadAuth({
    isAuth: false,
    right: "",
    userId: "",
    message: "",
    login: "",
    password: "",
  }));
  api(`/app/logout`, {
    method: "POST",
    data
  })
    .then(() => delLoginCookie())
};

export const getAuth = (state) => state.auth;

export const setLoadAuth = (body) => (dispatch) => {
  return new Promise((resolve) => {
    return resolve(dispatch(setAuth({...body})))
  });
}

export default authSlice.reducer;
