import {
  delLoginCookie,
  domainCookie,
  getCookie,
  handlerErrorMessage,
  setCookie,
  setLoginCookie,
  unAuthModal
} from "../../utils/api";
import {authConstants} from "../../constants/authConstants";


export const baseQueryWithReauth = (baseQuery) => async (args, api, extraOptions) => {
const options ={
  domain: domainCookie
}
  let result = await baseQuery(args, api, extraOptions);

  const currentRefreshToken = getCookie(authConstants.refreshToken);
  const currentCabinetId = getCookie(authConstants.userId);

  if (result.error && result.error.status === 403) {

    // create body according to requirements of endpoint  /token
    const body = {
      personalCabinetId: currentCabinetId, // id personal cabinet from cookie
      refreshToken: currentRefreshToken // refreshToken from cookie
    }

    // try to get a new accessToken
    const refreshResult = await baseQuery({
      url: '/token',
      method: "POST",
      body
    }, api, extraOptions);
    // if status response 200 - go for couple access and refresh token to endpoint /refresh
    if (refreshResult.data && refreshResult.data.userId) {
      // if success, set new updated accessToken in cookie
      setCookie(authConstants.accessToken, refreshResult.data.accessToken, options);

      const currentRefreshToken = getCookie(authConstants.refreshToken);
      // create body according to requirement of endpoint /refresh
      const body = {
        personalCabinetId: refreshResult.data.cabinetId,
        refreshToken: currentRefreshToken
      }

      const refreshBothTokenResult = await baseQuery({
        url: '/refresh',
        method: "POST",
        body
      }, api, extraOptions);

      // if status response 401 - show modal and redirect to  /login page
      if (refreshBothTokenResult.error && refreshBothTokenResult.error.status === 401) {
        handlerErrorMessage(refreshBothTokenResult.error.status, refreshBothTokenResult.error, true);
      }
      // if success - update cookie
      setLoginCookie(refreshBothTokenResult.data);
      // send request to that endpoint, which fail in the start with 403 status error
      result = await baseQuery(args, api, extraOptions);
      // if error - show modal with text according to the status
      result?.error?.status && handlerErrorMessage(result.error.status, result.error.data, true);

    } else if (refreshResult.error && refreshResult.error.status === 401) {
      // if answer status 401 show modal and redirect to /login page
      delLoginCookie();
      unAuthModal(args.url);
      return;
    }
  } else if (result.error && result.error.status) {
    // if error - show modal with text according to the status
    handlerErrorMessage(result?.error?.data?.status, result?.error?.data?.message, true);
  }
  return result
}




