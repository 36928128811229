import i18next from "i18next";

export const regionOptions = [
  {label: i18next.t("Kazakhstan"), value: 16},
  {label: i18next.t("otherCountry"), value: 198},
];

export const countryFormatter = (countyId) => {
  switch (+countyId) {
    case 198:
      return i18next.t("otherCountry");
    case 16:
      return i18next.t("Kazakhstan");
    default:

      return ""
  }
}