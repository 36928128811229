import React, {useCallback, useState} from "react";
import BaseSelect from "react-select";
import {AiOutlineCopy} from "@react-icons/all-files/ai/AiOutlineCopy";
import Tooltips from "./Tooltips";
import {useTranslation} from "react-i18next";

// const CustomDropdownIndicator = (...props) => {
//   return <img src={SelectDropDown} alt="" />;
// };

export const Select = ({
                         disabled,
                         isMulti,
                         options,
                         getOptionLabel,
                         formatOptionLabel,
                         placeholder,
                         noOptionsMessage,
                         className,
                         noDataText,
                         onChange,
                         defaultValue,
                         value,
                         textForCopy = "",
                         ...props
                       }) => {
  const [isCopied, setIsCopied] = useState(false);
  const {t}=useTranslation();

  const setClearTooltip = useCallback(() => {
    setIsCopied(false)
  }, [])

  const handleCopyText = useCallback(async () => {
    setIsCopied(true)
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(value.textForCopy);
    } else {
      let tempInput = document.createElement("input");
      tempInput.value = value.textForCopy;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
    }
  }, [value, isCopied]);

  return (
    <div className={"d-flex"}>
      <BaseSelect
        {...props}
        className={"simple-select " + (className ? className : "")}
        classNamePrefix={"simple-select"}
        // components={{ DropdownIndicator: CustomDropdownIndicator }}
        options={options}
        // defaultMenuIsOpen={true}
        defaultValue={defaultValue}
        getOptionLabel={getOptionLabel}
        formatOptionLabel={formatOptionLabel}
        isMulti={isMulti}
        value={value}
        isOptionDisabled={(option) => option.disabled}
        onChange={(option, inputObj) => {
          // console.log("inputObj111", inputObj)
          onChange && onChange(option, inputObj)
        }}
        placeholder={placeholder ? placeholder : ""}
        noOptionsMessage={() => (noDataText ? noDataText : t("noOptions"))}
        isDisabled={disabled}
        isClearable={false}
        // isOptionDisabled
      />
      {textForCopy && <Tooltips
        className={" "}
        text={isCopied ? "Copied" : value.textForCopy}
        tooltipId={value.textForCopy}
      >
        <div className={""}>
          {/*<span className={"tooltip_text"}>{isCopied ? "Copied": textForCopy}</span>*/}
          <button
            className={"btn btn-transparent "}
            type={"button"}
            onMouseLeave={setClearTooltip}
            // onBlur={setClearTooltip}
            onClick={handleCopyText}>
            <AiOutlineCopy/>
          </button>
          {/*<div className={"tooltip_text"} style={{minWidth: "150px"}}>{isCopied ? "Copied" : textForCopy}</div>*/}
        </div>

      </Tooltips>}
    </div>

  );
};
