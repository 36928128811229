import React from "react";
import {Select} from "components/Select";
import {useTranslation} from "react-i18next";

// This is a custom filter UI for selecting
// a unique option from a list
export const SelectColumnFilter= ({
                                     column: {filterValue, setFilter, preFilteredRows, id},  gotoPage
                                   }, changedOptions = [], className="") => {
  // console.log("changedOptions", id, changedOptions)
  // Calculate the options for filtering
  // using the preFilteredRows
  const {t}=useTranslation();
  const options = React.useMemo(() => {
    const optionsValue = new Set();
    const options = new Set();

    preFilteredRows.forEach(row => {
      if (row.values[id] === "" || row.values[id] === undefined || row.values[id] === null) return
      optionsValue.add(row.values[id]);
    });
    // id==="serviceTypeText" && console.log("optionsValue", id, optionsValue)
    optionsValue.forEach(item => {
      // id==="serviceTypeText" && console.log("optionsValue", item)
      let label = changedOptions.length ? changedOptions.filter(option=>{
        // id==="serviceTypeText" && console.log("option11111", item, option);
        return option.value === item
      })[0]?.label : item;
      if (item === "" || item === undefined) return
      options.add({label: label, value: item})
    })

    return [{label: t("all"), value: t("all")}, ...options.values()]
  }, [id, preFilteredRows, changedOptions]);

  // id==="serviceTypeText" && console.log("id", id, options)

  return (
    <div className={`pt-2 w-100 ${className}`}>
      <Select
        className={"small"}
        disabled={false}
        options={
          // changedOptions ? changedOptions :
          options
        }
        value={filterValue === undefined ? {label: t("all"), value: t("all")} : {
          label:
            changedOptions.length
            ? changedOptions.filter(item => {
                // console.log("condition22222______________", item.value === filterValue, item.value, filterValue)
            return item.value === filterValue
          })[0]?.label :
            filterValue,
          value: filterValue
        }}
        onChange={(e) => {
          gotoPage(0);
          setFilter(e.value !== t("all") ? e.value : undefined)
        }}
      />
    </div>

  )
}