export default function checkRights(rights, userRight) {

  if (!rights || !rights.length) {
    return true;
  }

  if (rights.includes(userRight)) {
    return true;
  }

  return false;
}
