import i18next from "i18next";

export const DataFormatter = (docType) => {
  switch (docType) {

    // mainTab: "Мой баланс",
    //   clientTab: "Балансы клиентов",
    //   operationName: "Название операции",
    //   from: "От кого",
    //   to: "Кому",
    //   date: "Дата платежа",
    //   id: "ID",
    //   sum: "Сумма платежа",
    //   balanceReplenishment: "Пополнение баланса",
    //   balanceReplenishment1C: "Пополнение баланса центра клиентов",
    //   replenishmentClientBalance: "Пополнение баланса клиента",
    //   replenishmentAdAccount: "Пополнение рекламного аккаунта",
    //   commission: "Комиссия",
    //   refundsAdAccount: "Возврат средств с рекламного аккаунта",
    //   refundsClientCenter: "Возврат средств центру клиентов",
    //   bonus: "Бонусы",
    //   client: "Клиент",
    //   centerClient: "Центр клиентов",
    //   adAccount: "Аккаунт",
    //   payLine: "Платежная линия",
    //   transfer: "Перевод средств",


    case "balanceReplenishment":
      return i18next.t("topUpBalance");
    case "balanceReplenishment1C":
      return i18next.t("balanceReplenishment1C");
    case "replenishmentClientBalance":
      return i18next.t("replenishmentClientBalance");
    case "replenishmentAdAccount":
      return i18next.t("replenishmentAdAccount");
    case "commission":
      return i18next.t("commission");
    case "refundsAdAccount":
      return i18next.t("refundsAdAccount");
    case "refundsClientCenter":
      return i18next.t("refundsClientCenter");
    case "bonus":
      return i18next.t("bonuses");
    case "client":
      return i18next.t("client");
    case "centerClient":
      return i18next.t("centerClient");
    case "adAccount":
      return i18next.t("account");
    case "payLine":
      return  i18next.t("Payline");
    case "transfer":
      return i18next.t("transfer");
    case "commissionRefund":
      return i18next.t("commissionRefundOperation");
    case "blockedAccountRefund":
      return i18next.t("blockedAccountRefund");
    case "fine":
      return i18next.t("fineOperation");
    case "manualDeduct":
      return i18next.t("manualDeductOperation");
    case "googleTax":
      return  i18next.t("googleTax")
    default:
      return ""
  }
}