import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {getCookie, requestURl} from "../../utils/api";
import {authConstants} from "../../constants/authConstants";
import {baseQueryWithReauth} from "./settings";


const baseQuery = (args, api, extraOptions) => fetchBaseQuery(
  {
    baseUrl: requestURl,
    prepareHeaders: (headers, {getState}) => {
      const token = getCookie(authConstants.accessToken);
      const tokenType = getCookie(authConstants.typeToken);
      if (token !== "undefined") {
        // устанавливаем Authorization headers
        headers.set("Authorization", `${tokenType} ${token}`);
      }
      if (extraOptions?.isImg) {
//responseType: "blob"
//         headers.set("responseType", "blob");
        //image/jpeg
        headers.set("Content-Type", "image/jpeg");
      } else {
        headers.set("Content-Type", "application/json");
      }
      // headers.set("Content-Type", "application/json");
      return headers
    },
  });


export const apiRedux = createApi({
  reducerPath: 'apiRedux',
  baseQuery: (args, api, extraOptions) => {
    return baseQueryWithReauth(baseQuery(args, api, extraOptions))(args, api, extraOptions)
  },
  endpoints: (builder) => ({}),
})




