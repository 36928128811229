import React, {useCallback, useState} from 'react';
import Modal from "./Modal";
import {Select} from "../Select";
import api from "../../utils/api";
import {OpenLineType} from "../../utils/OpenLineType";
import {useDispatch} from "react-redux";
import {loadTableData} from "../../redux/reducers/tableData";
import {useTranslation} from "react-i18next";

const OpenLineModal = ({show, closeCallback, id}) => {
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handelOpenLine = useCallback(() => {
    return api(`/clients/switch-open-line/${id}`, {
      method: "PUT",
      data: {
        "openLine": value.value
      }
    }).then(() => {
      dispatch(loadTableData("clients", {disabledOn: 0}))
    })
  }, [value]);


  return (
    <Modal
      show={show}
      title={t("settingForOpenLine")}
      closeCallback={closeCallback}
      style={{minWidth: "360px"}}
    >
      <>
        <div className={""}>
          <label className={"text-muted font-size-12"}>{t("chooseTypeOL")}:</label>
          <Select
            options={OpenLineType(t)}
            value={value}
            onChange={(e) => setValue(e)}
          />
          <div className={"d-flex justify-content-center pt-5 mt-5"}>
            <button className={"btn btn-primary mt-3 px-4"}
                    disabled={!value}
                    onClick={handelOpenLine}
            >{t("save")}
            </button>
          </div>
        </div>
      </>
    </Modal>
  );
};

export default OpenLineModal;