import React, {memo, useCallback, useState} from 'react';
import Modal from "./Modal";
import {Select} from "../Select";
import api from "utils/api";
import {useDispatch, useSelector} from "react-redux";
import {getTableData, loadTableData} from "../../redux/reducers/tableData";
import {useTranslation} from "react-i18next";

const ClientTagModal = memo(({show, closeCallback, id, managementTag, clientTag}) => {

  const tagList = useSelector(getTableData)["managementTag"] || [];
  const tagClientsList = useSelector(getTableData)["clientTag"] || [];

  const [value, setValue] = useState(clientTag !== null && tagClientsList?.filter(item => item.label === clientTag)[0] || null);
  const [valueManagement, setValueManagement] = useState(tagList?.filter(item => item.label === managementTag)[0] || null);

  const [newManagementTag, setNewManagementTag] = useState("");
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handelSetTag = useCallback((clientTag, managementTag) => {
    return api(`/clients/switch-client-tag/${id}`, {
      method: "PUT",
      data: {
        clientTag,
        managementTag
      }
    }).then(() => {
      swal({
        icon: "success",
        text: t("tagToClient")
      }).then(() => {
        dispatch(loadTableData("clients", {disabledOn: 0}));
      })
    })
  }, [value, valueManagement, newManagementTag, t]);

  const handelCreateManagementTag = useCallback(() => {
    return api(`/tags/management/create`, {
      method: "POST",
      data: {
        label: newManagementTag,
      },
    })
      .then((res) => {
        res.value && handelSetTag(value?.value, res.value || valueManagement?.value)
        newManagementTag && dispatch(loadTableData("managementTag"))
      });
  }, [newManagementTag])


  return (
    <Modal
      show={show}
      title={t("settingForClientTag")}
      closeCallback={closeCallback}
      notCloseOnOverlay={true}
      style={{minWidth: "360px"}}
    >
      <>
        <div className={"pb-4"}>
          <label className={"text-muted font-size-12"}>{t("chooseClientFinTag")}</label>
          {/*<div className={""}>*/}
          {/*  <label className={"text-muted font-size-12"}>{t("chooseClientTag")}:</label>*/}
          <Select
            options={tagClientsList}
            // options={ClientTagType(t)}
            value={value}
            onChange={(e) => setValue(e)}
          />
        </div>

        <div className={"pb-4"}>
          <label className={"text-muted font-size-12"}>{t("chooseClientManagementTag")}</label>
          <Select
            options={tagList}
            value={valueManagement}
            onChange={(e) => setValueManagement(e)}
          />
        </div>
        <div className={"py-4"}>
          <label className={"text-muted font-size-12 d-block"}>{t("createManagementTag")}</label>
          <input
            className={"form-control"}
            value={newManagementTag}
            onChange={(e) => setNewManagementTag(e.target.value)}
          />
        </div>
        <div className={"pt-4 d-flex justify-content-center"}>
          <button className={"btn btn-primary px-4"}
                  disabled={(!newManagementTag || !valueManagement) && !value}
                  onClick={() => !newManagementTag ? handelSetTag(value?.value, valueManagement?.value) : handelCreateManagementTag()}
          >{t("save")}
          </button>
        </div>

      </>
    </Modal>
  );
});

export default ClientTagModal;