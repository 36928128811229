import React, {memo, useCallback, useState, useEffect} from "react";
import CommentsIcon from "assets/images/comments.svg";
import ModalEditRequestFB from "components/Modal/ModalEditRequestFB";
import ModalRevisionFBAcc from "../components/Modal/ModalRevisionFBAcc";
import swal from "sweetalert";
import {useDispatch, useSelector} from "react-redux";
import {loadTableData} from "reduxStore/reducers/tableData";
import ModalAddIdFBAccount from "../components/Modal/ModalAddIdFBAccount";
import {requestFBStatus} from "../constants/Constants";
import api from "../utils/api";
import {SelectColumnFilter} from "../components/Table/SelectedColumnFilter";
import {
  DefaultColumnFilter,
  filterTypes,
  getExcel,
  GlobalFilter,
  TRBody,
  TRHead
} from "../utils/FilteredTable";
import Edit from "assets/images/rewrite-icon.svg";
import SuccessIcon from "assets/images/success-icon.svg";
import Basket from "assets/images/reject.svg";
import Back from "assets/images/back.svg";
import Id from "assets/images/id.svg";
import Show from "assets/images/show-icon.svg";
import ForEdit from "assets/images/edit.svg";
import {Roles} from "../common/RoleFormatter";
import {getAuth} from "reduxStore/reducers/auth";
import {useFilters, useGlobalFilter, usePagination, useSortBy, useTable} from "react-table";
import Pagination from "../common/Paginations";
import BTable from "react-bootstrap/Table";
import {getTableData} from "../redux/reducers/tableData";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import Tooltips from "../components/Tooltips";
import {dataToday} from "../utils/date";
import AttentiveIcon from "assets/images/attentionIcon.svg?svgr";
import {optionsSelfReg} from "./Clients";

// const Tooltips = memo(({text, children}) => {
//   return <div data-html={true}
//               data-place={"bottom"}
//               data-type={'light'}
//               data-effect={"solid"}
//               data-tip={text}
//               data-for='test'
//               className={""}>
//     {children}
//     <ReactTooltip id='test' border={true}
//                   data-border-class={"border"}
//                   borderColor={"#ccc"}/>
//   </div>
// });

const ActionColumn = memo(({row}) => {
  const [showEditModal, setShowEditModal] = useState({show: false});
  const [showRevisionModal, setShowRevisionModal] = useState({show: false});
  const [showModalAddIdAccount, setShowModalAddIdAccount] = useState({show: false});
  const {right: userRight} = useSelector(getAuth);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleApproveAcc = useCallback((request) => {
    let data = request;
    return (
      swal({
        text: t("approveConfirmText"),
        icon: "warning",
        buttons: [t("no"), t("yes")],
        className: "confirm-simple-modal"
      }).then((response) => {
        if (response) {
          api(`/facebook/registration/status/process/${data.id}`, {
            method: "GET",
          })

            .then((response) => {
              if (response) {
                dispatch(loadTableData("requestFB"));
                swal({
                  icon: "success",
                  text: t("inProcessingSuccess")
                })
              }
            })
        }
      })
    )
  }, [dispatch]);

  const handleChangeStatus = useCallback((request) => {
    let data = request;
    return (
      swal({
        text: t("returnToNewConfirm"),
        icon: "warning",
        buttons: [t("no"), t("yes")],
        className: "confirm-simple-modal"
      }).then((response) => {
        if (response) {
          api(`/facebook/registration/status/new/${data.id}`, {
            method: "GET",
          })
            .then((response) => {
              if (response) {
                dispatch(loadTableData("requestFB"));
                // setFbTable
                swal({
                  icon: "success",
                  text: t("returnToNewSuccess")
                })
              }
            })
        }
      })
    )
  }, [dispatch])

  const handleRemoveRequest = useCallback((request) => {
    swal({
      text: t("delRequestConfirm"),
      icon: "warning",
      buttons: [t("no"), t("yes")],
      className: "confirm-simple-modal"
    }).then((response) => {
      if (response) {
        api(`/facebook/registration/delete/${request.id}`, {
          method: "GET",
        })
          .then(() => {
            dispatch(loadTableData("requestFB"));
            swal({
              icon: "success",
              text: t("delRequestSuccess")
            })
          })
      }
    })

  }, [dispatch]);

  const handleShowEditModal = useCallback((row) => {
    setShowEditModal({show: true, data: row.original, disabled: false})
  }, [])

  return (
    <>
      <div
        className={`text-center ${row.original.status === requestFBStatus.rejected || row.original.status === requestFBStatus.close ? "py-2" : ""}`}>
        <div className="text-center">
          {row.original.status !== requestFBStatus.inProcessing && row.original.status !== requestFBStatus.rejected && row.original.status !== requestFBStatus.close && <>
            <Tooltips text={t("edit")} tooltipId={`${row.original.id}_edit`} className={""}>
              <button
                className="btn btn-primary mr-1 small-btn"
                style={{padding: "6px 9px"}}
                // onClick={() => setShowEditModal({show: true, data: row.original, disabled: false})}
                onClick={() => handleShowEditModal(row)}
                disabled={userRight === Roles.ROLE_FINANCIER || userRight === Roles.ROLE_SUPPORT}
              >
                <img src={Edit} alt={t("edit")}/>
              </button>
            </Tooltips>
          </>}
          <Tooltips text={t("watching")}
                    className={""}
                    tooltipId={`${row.original.id}_watching`}>
            <button
              data-for='show-btn'
              className="btn btn-primary mr-1 small-btn"
              style={{padding: "6px 7px"}}
              onClick={() => setShowEditModal({show: true, data: row.original, disabled: true})}
            >
              <img src={Show} alt={t("watching")}/>
            </button>
          </Tooltips>


          {row.original.status === requestFBStatus.new && <Tooltips
            className={""}
            text={t("sentForRevision")}
            tooltipId={`${row.original.id}_sentForRevision`}>
            <button

              disabled={userRight === Roles.ROLE_FINANCIER || userRight === Roles.ROLE_SUPPORT}
              className="btn btn-orange mr-1 small-btn"
              onClick={() => setShowRevisionModal({show: true, data: row.original})}
              style={{padding: "5px 7px"}}
            >
              <img src={ForEdit} alt={t("sentForRevision")}/>
            </button>
          </Tooltips>}
          {(row.original.status === requestFBStatus.forRevision || row.original.status === requestFBStatus.new) &&
            <Tooltips text={t("approve")}
                      className={""}
                      tooltipId={`${row.original.id}_approve`}>
              <button
                disabled={userRight === Roles.ROLE_FINANCIER || userRight === Roles.ROLE_SUPPORT}
                className="btn btn-success mr-1 small-btn"
                onClick={() => handleApproveAcc(row.original)}
                style={{padding: "6px 7px"}}
              >

                <img src={SuccessIcon} alt={t("approve")}/>
              </button>
            </Tooltips>}
          {(row.original.status === requestFBStatus.forRevision || row.original.status === requestFBStatus.inProcessing) &&
            <Tooltips text={t("backToNew")}
                      className={""}
                      tooltipId={`${row.original.id}_backToNew`}>
              <button className="btn btn-orange mr-1 small-btn"
                      style={{padding: "5px 7px"}}
                      disabled={userRight === Roles.ROLE_FINANCIER || userRight === Roles.ROLE_SUPPORT}
                      onClick={() => handleChangeStatus(row.original)}>
                <img src={Back} alt={t("backToNew")}/>
              </button>
            </Tooltips>}
          {(row.original.status === requestFBStatus.inProcessing) &&
            <Tooltips text={t("addIDFB")}
                      className={""}
                      tooltipId={`${row.original.id}_addIDFB`}>
              <button
                className="btn btn-orange mr-1 small-btn"
                style={{padding: "6px 7px"}}
                disabled={userRight === Roles.ROLE_FINANCIER || userRight === Roles.ROLE_SUPPORT}
                onClick={() => setShowModalAddIdAccount({show: true, data: row.original})}>
                <img alt={t("addIDFB")} src={Id}/>
              </button>
            </Tooltips>}
          {row.original.status !== requestFBStatus.rejected &&
            row.original.status !== requestFBStatus.close &&
            <Tooltips text={t("reject")}
                      className={""}
                      tooltipId={`${row.original.id}_delete`}>
              <button className={"btn mr-1 btn-danger small-btn"}
                      onClick={() => handleRemoveRequest(row.original)}
                      style={{padding: "6px 7px"}}
                      disabled={userRight === Roles.ROLE_FINANCIER || userRight === Roles.ROLE_SUPPORT || userRight === Roles.TECHNICAL_SPECIALIST}

              >
                <img src={Basket} alt={t("reject")}/>
              </button>
            </Tooltips>}

        </div>
      </div>

      <>
        {showEditModal.show && <ModalEditRequestFB
          show={showEditModal.show}
          disabled={showEditModal.disabled}
          closeCallback={() => setShowEditModal({show: false})}
          data={showEditModal.data}
        />
        }
        {showRevisionModal.show && <ModalRevisionFBAcc
          show={showRevisionModal.show}
          closeCallback={() => setShowRevisionModal({show: false})}
          data={showRevisionModal.data}
        />}
        {
          showModalAddIdAccount.show && <ModalAddIdFBAccount
            show={showModalAddIdAccount.show}
            closeCallback={() => setShowModalAddIdAccount({show: false})}
            data={showModalAddIdAccount.data}/>
        }

      </>

    </>)
});

function Table({
                 columns,
                 data,
                 pageSizeDefault,
                 exportName,
                 isExport
               }) {

  const {t} = useTranslation();
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {pageIndex, pageSize, globalFilter},
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: {
        pageSize: pageSizeDefault,
      },
      autoResetPage: false,
    },

    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className={"d-flex full-wrap-filtered-table bg-white p-3"}>
        <div className={"d-flex"}>
          <GlobalFilter
            gotoPage={gotoPage}
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </div>


        {rows.length > pageSize &&
          <div className={"px-4"}>
            <Pagination
              pageIndex={pageIndex}
              canPreviousPage={canPreviousPage}
              previousPage={previousPage}
              gotoPage={gotoPage}
              pageCount={pageCount}
              canNextPage={canNextPage}
              nextPage={nextPage}
            />
          </div>
        }
        {isExport ? <button className={"btn btn-outline-primary ml-auto ml-4"}
                            onClick={() => getExcel(exportName, headerGroups, rows, prepareRow)}>
          {t("exportToExcel")}
        </button> : null}
      </div>

      <div className={"filtered-table-wrapper"}>
        <BTable striped bordered hover size="sm" className={"bg-white filtered-table"} {...getTableProps()}>
          <thead>
          {headerGroups.map((headerGroup) => <TRHead {...headerGroup.getHeaderGroupProps()}
                                                     headerGroup={headerGroup}/>)}
          </thead>

          <tbody {...getTableBodyProps()}>
          <TRBody page={page} lastItemBold={false} length={headerGroups[0].headers.length} prepareRow={prepareRow}/>
          </tbody>

        </BTable>
      </div>
    </>
  )
}


export const statusFormatter = (status) => {
  switch (status) {
    case requestFBStatus.new:
      return i18next.t("new");
    case requestFBStatus.inProcessing:
      return i18next.t("inProcessing");
    case requestFBStatus.forRevision:
      return i18next.t("forRevision");
    case requestFBStatus.rejected:
      return i18next.t("rejectedStatus");
    case requestFBStatus.close:
      return i18next.t("close");
    case requestFBStatus.CHANGE_PAYER:
      return i18next.t("changePayer")
    default:
      return "";
  }
}

export const statusFbOptions = (t) => [
  {label: t("all"), value: t("all")},
  {label: statusFormatter(requestFBStatus.new), value: statusFormatter(requestFBStatus.new)},
  {label: statusFormatter(requestFBStatus.inProcessing), value: statusFormatter(requestFBStatus.inProcessing)},
  {label: statusFormatter(requestFBStatus.forRevision), value: statusFormatter(requestFBStatus.forRevision)},
  {label: statusFormatter(requestFBStatus.rejected), value: statusFormatter(requestFBStatus.rejected)},
  {label: statusFormatter(requestFBStatus.close), value: statusFormatter(requestFBStatus.close)},
];

export const StatusElement = memo(({text, badgeColor = "badge-success"}) => {
  return (
    <div className="text-center">
      <div className={`text-center badge ${badgeColor}`}>
        {text}
      </div>
    </div>
  )
});

export const CommentElement = memo(({text, id}) => {
  return (
    <div className="text-center">
      {text && <Tooltips text={text}
                         className={""}
                         tooltipId={`${id}_comment`}>
        <div className="text-center btn btn-outline-primary">
          <img src={CommentsIcon} alt={"comment"}/>
        </div>
      </Tooltips>}
    </div>
  )
})

const RequestFB = memo(() => {
  const {right: userRight} = useSelector(getAuth);
  const data = useSelector(getTableData)["requestFB"] || [];
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const {right} = useSelector(getAuth);
  const isDisabled = right === Roles.ROLE_SUPPORT;
  const isTechnical = right === Roles.TECHNICAL_SPECIALIST;
  // const [selfReg, setSelfReg] = useState(false);
  // const [filteredData, setFilteredData] = useState(data || [])

  useEffect(() => {
    dispatch(loadTableData("sidebarInfo")).then(res => {
      res && dispatch(loadTableData("requestFB"))
    })

  }, []);

  // useEffect(() => {
  //   const result = selfReg ? data?.filter(item => item.isFirstCreation) : data;
  //   setFilteredData(result);
  // }, [selfReg]);

  const columns = React.useMemo(
    () => [
      {
        Header: t("paylineName"),
        accessor: 'payLineName',
        Filter: SelectColumnFilter,
        filter: 'equals',
        disableSortBy: true,
        Cell: ({row}) => {
          return (<div className={"d-flex align-items-center"}>
            <span>{row.original.payLineName}</span>
          </div>)
        }
      },


      {
        Header: t("isFirstCreation"),
        accessor: 'isFirstCreationText',
        Filter: ({...rest}) => SelectColumnFilter({...rest}, optionsSelfReg),
        filter: 'equals',
        disableSortBy: true,
        Cell: ({row}) => {
          return (<div className={"d-flex align-items-center"}>
            {row.original?.isFirstCreation && <Tooltips
              tooltipId={`${row?.original?.id}_isSelfReg`}
              text={"The client came through self-registration"}
              className={""}
            ><AttentiveIcon className={"d-block mr-2"} width={24} height={24} fill={"#0571E6"}/>
            </Tooltips>}
          </div>)
        }
      },
      {
        Header: t("clientEmail"),
        accessor: 'ownerLogin',
        Filter: SelectColumnFilter,
        filter: 'equals',
        disableSortBy: true,
      },
      {
        Header: t("requestCreationDate"),
        accessor: 'creationDate',
        Filter: SelectColumnFilter,
        filter: 'includes',
        disableSortBy: true,
      },
      {
        Header: t("BusinessManagerID"),
        accessor: 'managersIds',
        Filter: SelectColumnFilter,
        filter: 'equals',
        disableSortBy: true,
      },
      {
        Header: t("advName"),
        accessor: 'adwName',
        Filter: SelectColumnFilter,
        filter: 'includes',
        disableSortBy: true,
      },
      {
        Header: t("comment"),
        accessor: 'commentText',
        disableSortBy: true,
        Cell: ({row}) => <CommentElement row={row} text={row.original.commentText} id={row.original.id}/>,
        Filter: false,
      },
      {
        Header: t("status"),
        accessor: 'statusText',
        disableSortBy: true,
        Cell: ({row}) => <StatusElement
          text={row.values.statusText}
          badgeColor={row.original.status === requestFBStatus.rejected ? "badge-error" : "badge-success"}/>,
        Filter: ({...rest}) => SelectColumnFilter({...rest}, statusFbOptions(t)),
        filter: 'equals',
      },

      // {
      //   Header: 'Центр клиентов (email)',
      //   accessor: 'customerCenterLogin',
      //   Filter: SelectColumnFilter,
      //   filter: 'equals',
      // },

      //
      // {
      //   Header: 'Ссылка на рекламируемую страницу FB',
      //   accessor: 'linkToAdvertisingPage',
      //   Filter: SelectColumnFilter,
      //   filter: 'equals',
      // },

      {
        Header: ' ',
        accessor: 'buttons',
        disableSortBy: true,
        Cell: ({row}) => isDisabled ? null : <ActionColumn row={row}/>,
        Filter: false,
      },
    ],
    [statusFbOptions, userRight, t]
  )

  return (
    <>
      <h1>Facebook {t("requests")}</h1>
      {/*<label className={"d-flex align-items-center mb-3"}>*/}
      {/*  <input type={"checkbox"}*/}
      {/*         className={"mr-3"}*/}
      {/*         value={selfReg}*/}
      {/*         onChange={() => setSelfReg(!selfReg)}/>*/}
      {/*  {t("showSelfRegClients")}*/}
      {/*</label>*/}
      <Table
        // data={filteredData || data || []}
        data={data || []}
        columns={columns}
        pageSizeDefault={10}
        isExport={!(isDisabled || isTechnical)}
        exportName={`Facebook_${t("requests")}_${dataToday}`}
      />
    </>
  )
})

export default RequestFB