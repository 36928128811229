// import { configureStore } from "@reduxjs/toolkit";
import auth from "./auth";
import tableData from "./tableData";
import toggleSidebar from "./toggleSidebar";
//
// export default configureStore({
//   reducer: {
//     auth,
//     tableData,
//     toggleSidebar,
//   },
// });




import { configureStore } from '@reduxjs/toolkit'
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from '@reduxjs/toolkit/query'
import {createBrowserHistory} from "history";
import {rootReducer} from "reduxStore/reducers";
import {connectRouter, routerMiddleware} from 'connected-react-router';
import thunk from 'redux-thunk';
import {apiRedux} from "./apiRedux";

export const history = createBrowserHistory();
const test = {
  // ...rootReducer(history),
  // router: connectRouter(history),
      auth,
    tableData,
    toggleSidebar,
  [apiRedux.reducerPath]: apiRedux.reducer,
}


const preloadedState = {};

const store = configureStore({
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiRedux.middleware)
      .concat([thunk, routerMiddleware(history)]),

  // getDefaultMiddleware().concat(apiRedux.middleware),
  reducer: test,
  preloadedState,

})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export default store
