import {apiRedux} from "../reducers/apiRedux";
import {apiUrls} from "../../constants/appUrls";

const GoogleMccApi = apiRedux.injectEndpoints({
  endpoints: (build) => ({

    getGoogleMiddleMcc: build.query({
      query: () => ({
        url: apiUrls.getMiddleMcc
      })
    }),

    getAvailableMcc: build.query({
      query: () => ({
        url: apiUrls.getAvailableMcc
      }),
      providesTags: (result) => [{type: 'availableMcc', id: result.id}],
    }),

    getAssignedMcc: build.query({
      query: () => ({
        url: apiUrls.getAssignedMcc
      }),
      providesTags: (result) => [{type: 'assignedMcc', id: result.id}],
    }),

    createMcc: build.mutation({
      query: (data) => ({
        url: apiUrls.createMcc,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['availableMcc'],
    }),

  })
});

export const UseGetGoogleMiddleMccApi = GoogleMccApi.useGetGoogleMiddleMccQuery;
export const UseGetAssignedMccApi = GoogleMccApi.useLazyGetAssignedMccQuery;
export const UseCreateMccApi = GoogleMccApi.useCreateMccMutation;
export const UseGetAvailableMcc = GoogleMccApi.useGetAvailableMccQuery;
