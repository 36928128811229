import React from "react";


const FormikTextarea = ({className, name, formik, disabled = false, height = "80px"}) => {
  return (
    <div className={className}>
      <textarea
        style={{height}}
        id={name}
        name={name}
        disabled={disabled}
        className={`form-control ${formik.touched[name] && formik.errors[name] ? "border-danger" : ""}`}
        {...formik.getFieldProps(name)}
      >{formik.values[name] || ""}</textarea>
      {formik.touched[name] && formik.errors[name] ? (
        <div className={"position-relative"}>
          <div className="text-danger" style={{top: "0"}}>{formik.errors[name]}</div>
        </div>
      ) : null}
    </div>
  )

}

export default FormikTextarea;