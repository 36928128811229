import React from "react";

export default function Modal({
  show,
  className = "",
  children,
  notCloseOnOverlay = true,
  closeCallback,
  style,
  title,
}) {
  return (
    <div
      onClick={() =>
        closeCallback && !notCloseOnOverlay ? closeCallback() : null
      }
      className={`simply-modal ${show ? "" : "d-none"}`}
    >
      <div
        style={style}
        onClick={(e) => e.stopPropagation()}
        className={`bg-white rounded-lg m-auto px-0 center-position-modal ${className}`}
      >
        <div className="simply-modal__header">
          <span>{title}</span>
          <span
            className="simply-modal__close"
            onClick={() => closeCallback()}
          />
        </div>
        <div className="simply-modal__body p-4">{children}</div>
      </div>
    </div>
  );
}
