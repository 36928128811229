import React from "react";
import {Select} from "components/Select";

// This is a custom filter UI for selecting
// a unique option from a list
export function MultiSelectedColumnFilter(props) {
  // console.log("props", props)
  const {setFilter, preFilteredRows, id, filterValue} = props.column;
  const {filterTypes, gotoPage} = props;
  // console.log("props", props);

  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const optionsValue = new Set();
    const options = new Set();

    preFilteredRows.forEach(row => {
      if (row.values[id] === "" || row.values[id] === undefined) return
      optionsValue.add(row.values[id]);
    });

    optionsValue.forEach(item => {
      if (item === "" || item === undefined) return
      options.add({label: item, value: item})
    })

    return [...options.values()]
  }, [id, preFilteredRows]);

  return (
    <div className={"pt-2 w-100"}>
      <Select
        className={"small"}
        isMulti={true}
        disabled={false}
        options={options}
        value={filterValue === undefined ? [] : [...filterValue].map((item) => {
          return {label: item, value: item}
        })}
        onChange={(e) => {
          let search = [];
          gotoPage(0);
          e.forEach(({value}) => search.push(value));
          filterTypes.multiSelect(preFilteredRows, id, search)
          setFilter(search.length !== 0 ? search : undefined)
        }}
      />
    </div>

  )
}