export const Roles = Object.freeze({
  ROLE_ADMIN: "ROLE_ADMIN",
  ROLE_HEAD: "ROLE_HEAD",
  ROLE_FINANCIER: "ROLE_FINANCIER",
  ROLE_ACCOUNTING: "ROLE_ACCOUNTING",
  ROLE_SUPPORT: "ROLE_SUPPORT",
  ROLE_TEAM_LEAD: "ROLE_TEAM_LEAD",
  ROLE_SALE_MANAGER: "ROLE_SALE_MANAGER",
  TECHNICAL_SPECIALIST: "ROLE_TECHNICAL_SPECIALIST"
})

export const RoleOptionsForTeamLead = [
  {label: Roles.ROLE_FINANCIER, value: Roles.ROLE_FINANCIER},
  {label: Roles.ROLE_ACCOUNTING, value: Roles.ROLE_ACCOUNTING},
  {label: Roles.ROLE_SUPPORT, value: Roles.ROLE_SUPPORT},
  {label: Roles.ROLE_SALE_MANAGER, value: Roles.ROLE_SALE_MANAGER},
  {label: Roles.TECHNICAL_SPECIALIST, value: Roles.TECHNICAL_SPECIALIST},

];

export const RoleForHead = RoleOptionsForTeamLead.concat([
  {label: Roles.ROLE_TEAM_LEAD, value: Roles.ROLE_TEAM_LEAD}
]);

export const RoleOptionsWithoutAll = RoleForHead.concat([
  {label: Roles.ROLE_ADMIN, value: Roles.ROLE_ADMIN},
  {label: Roles.ROLE_HEAD, value: Roles.ROLE_HEAD},
]);


export const RoleOptions = [{label: "Все", value: "Все"},].concat(RoleOptionsWithoutAll)