import {apiRedux} from "../../redux/reducers/apiRedux";
import {notifyMe} from "../Notifications/Notification";
import {getCookie} from "../../utils/api";
import {authConstants} from "../../constants/authConstants";

export const websocketMessageTypes = Object.freeze({
  PAYMENT_NOTIFICATION: "PAYMENT_NOTIFICATION",
  SESSION_ID: "SESSION_ID"

})

const websocketApi = apiRedux.injectEndpoints({
  endpoints: (build) => ({
    GetMessage: build.query({
      queryFn: async () => {
        const token = getCookie(authConstants.accessToken);

        function connect(token) {
          const url = process.env.WS_PATH; // 'ws://185.132.177.102:3011/socket/connect'
          let ws = new WebSocket(`${url}/socket/connect`);
          ws.onopen = function () {
            ws.send(token);
          };

          ws.onmessage = function (event) {
            const data = event.data;
            const messageData = !data.includes("Error") && JSON.parse(event?.data);

            if (!data.includes("Error") || messageData?.type !== "ERROR") {
              // const messageData = JSON.parse(event?.data);
              const type = messageData?.type;
              if (type === websocketMessageTypes.SESSION_ID) {
                sessionStorage.setItem(websocketMessageTypes.SESSION_ID, messageData?.body)
              }

              type === websocketMessageTypes.PAYMENT_NOTIFICATION && notifyMe(messageData?.body, messageData?.notificationId)

              return messageData?.body

            } else {
              console.log("TEST1")
              // refreshTokenForWebsocket()
            }

          };

          ws.onclose = function (e) {
            console.log('Socket is closed. Reconnect will be attempted in 1 second.', e);
            const refreshToken = getCookie(authConstants.refreshToken);
            if(refreshToken){
              // refreshTokenForWebsocket();
              // setTimeout(function () {
              //   const token = getCookie(authConstants.accessToken);
              //   connect(token);
              // }, 10000);
            }


          };

          ws.onerror = function (err) {
            console.error('Socket encountered error: ', err.message, 'Closing socket');
            ws.close();
          };
        }

        connect(token);

        // let websocket ;
        //
        //
        // try {
        //
        //   websocket = new WebSocket('ws://185.132.177.102:3011/socket/connect');
        //   websocket.onopen = () => {
        //     console.log('WebSocket connected');
        //     websocket.send(data);
        //   };
        //
        //   websocket.onmessage = (event) => {
        //     const data = event.data;
        //
        //     if (!data.includes("Error")) {
        //       const messageData = JSON.parse(event?.data);
        //       const type = messageData?.type;
        //       if (type === websocketMessageTypes.SESSION_ID) {
        //         sessionStorage.setItem(websocketMessageTypes.SESSION_ID, messageData?.body)
        //       }
        //
        //       type === websocketMessageTypes.PAYMENT_NOTIFICATION && notifyMe(messageData?.body, messageData?.notificationId)
        //
        //       return messageData?.body
        //
        //     }
        //
        //
        //   };
        //
        //   websocket.onclose = () => {
        //     console.log('WebSocket disconnected');
        //     // sessionStorage.removeItem('ws-connected');
        //   };
        //
        //   return {data: true}
        // } catch (error) {
        //   // Catch any errors and return them as an object with an `error` field
        //   return {error}
        // }
      },
    }),


    // SendMessage: build.mutation({
    //   queryFn: async (data) => {
    //     console.log("data token?", data)
    //
    //     try {
    //       const socket = await websocket;
    //       // Return the result in an object with a `data` field
    //       // socket.onopen=()=>{
    //       //   console.log("open")
    //       // }
    //       // socket.readyState && socket.send(data);
    //       socket.addEventListener('open', ()=>console.log("open"))
    //       socket.addEventListener('message ', ()=>console.log("message"))
    //       socket.addEventListener('close ', ()=>console.log("close"))
    //       socket.onopen = ()=>{
    //         console.log("open");
    //         socket.send(data)
    //       }
    //       console.log("socket1", socket)
    //       return { data: true }
    //     } catch (error) {
    //       // Catch any errors and return them as an object with an `error` field
    //       return { error }
    //     }
    //   },
    //   // async onCacheEntryAdded(
    //   //   arg,
    //   //   {updateCachedData, cacheDataLoaded, cacheEntryRemoved}
    //   // ) {
    //   //   // create a websocket connection when the cache subscription starts
    //   //   const ws = new WebSocket('ws://185.132.177.102:3011/socket/connect');
    //   //   console.log("ws", ws)
    //   //   try {
    //   //
    //   //     // wait for the initial query to resolve before proceeding
    //   //     await cacheDataLoaded;
    //   //
    //   //     console.log("ws2", ws)
    //   //
    //   //
    //   //     // when data is received from the socket connection to the server,
    //   //     // if it is a message and for the appropriate channel,
    //   //     // update our query result with the received message
    //   //     const listener = (event) => {
    //   //       const data = JSON.parse(event.data)
    //   //       console.log("data test", data )
    //   //       if (data.channel !== arg) return
    //   //
    //   //       updateCachedData((draft) => {
    //   //         draft.push(data)
    //   //       })
    //   //     }
    //   //
    //   //     ws.addEventListener('message', listener)
    //   //   } catch {
    //   //     // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
    //   //     // in which case `cacheDataLoaded` will throw
    //   //   }
    //   //   // cacheEntryRemoved will resolve when the cache subscription is no longer active
    //   //   await cacheEntryRemoved
    //   //   // perform cleanup steps once the `cacheEntryRemoved` promise resolves
    //   //   ws.close()
    //   // },
    // }),
  }),
})

export const UseGetMessageApi = websocketApi.useGetMessageQuery;
export const UseLazyGetMessageApi = websocketApi.useLazyGetMessageQuery;
