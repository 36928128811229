import i18next from "i18next";

export const adStatusFormatter = (status) => {
  switch (status) {
    case "ACTIVE":
    case "0":
      return i18next.t("accStatusActive");
    case "UNSPECIFIED":
    case "3":
      return i18next.t("accStatusUnspecified");
    case "DISABLE":
    case "1":
      return i18next.t("accStatusDISABLE");
    case "PAUSED":
    case "4":
      return i18next.t("accStatusPAUSED");
    case "ARCHIVED":
    case "8":
      return i18next.t("accStatusARCHIVED");
    case "DEACTIVATED":
    case "9":
      return i18next.t("accStatusDEACTIVATED");
    case "CLOSED":
    case "6":
      return i18next.t("accStatusCLOSED");
    case "BLOCKED":
    case "5":
      return i18next.t("accStatusBLOCKED");
    case "INACCESSIBLE":
    case "7":
      return i18next.t("accStatusINACCESSIBLE");
    case "ENABLED":
      return i18next.t("accStatusENABLED");
  }
}