if (!String.prototype.formatURL) {
  String.prototype.formatURL = function (params) {
    if (typeof params !== "object")
      throw new TypeError("Format url accepts only objects");
    let result = this;
    Object.keys(params).forEach((key) => {
      result = result.replace(`:${key}`, params[key]);
    });
    return result;
  };
}