import React from "react";
import {useSelector, useDispatch} from "react-redux";

import Burger from "assets/images/burger.svg";
import BigLogo from "assets/images/logo.svg";
import ExitIcon from "assets/images/exit.svg";
import Avatar from "assets/images/avatar.svg";

import {logout} from "reduxStore/reducers/auth";
import {
  getToggleSidebar,
  setToggleSidebar,
} from "reduxStore/reducers/toggleSidebar";
import {useHistory} from "react-router-dom";
import {getAuth} from "../redux/reducers/auth";
import {websocketMessageTypes} from "../components/websocket/WebsocketApi";
import {UseCloseSocketSession} from "../redux/api/DisconnectSocket";

export default function Users() {
  const dispatch = useDispatch();
  const {isOpen} = useSelector(getToggleSidebar);
  const history = useHistory();
  const {right, login, name} = useSelector(getAuth);
  const sessionId = sessionStorage.getItem(websocketMessageTypes.SESSION_ID);
  const [disconnectSocket] = UseCloseSocketSession();

  return (
    <header className={`header ${isOpen ? "open" : ""}`}>
      <div className="ml-4 ">
        <button
          className="border-0 bg-white outline-none burger-button"
          onClick={() => {
            dispatch(setToggleSidebar({isOpen: !isOpen}));
          }}
        >
          <img src={Burger} alt="burger-menu"/>
        </button>
      </div>
      <div style={{height: "24px"}}>
        <img src={BigLogo} alt={"logo"} className="h-100"/>
      </div>
      <div className={"d-flex align-items-center"}>
        {/*<SwitchLang/>*/}
        <div className={""}>
          <img src={Avatar} alt={"avatar"}/>
        </div>
        <div className={"px-3"}>
          <div className={"font-weight-bold"}>{login}</div>
          <div>{name || right}</div>
        </div>
        <button
          className="border-0 bg-white outline-none"
          style={{height: "24px"}}
          onClick={() => {
            disconnectSocket(sessionId);
            sessionStorage.removeItem(websocketMessageTypes.SESSION_ID);
            dispatch(logout);
            history.push(`${process.env.PUBLIC_URL}/login`);
          }}
        >
          <img src={ExitIcon} alt="" className="h-100"/>
        </button>
      </div>

    </header>
  );
}
