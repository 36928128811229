import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';

import { Provider } from "react-redux";
import "assets/css/bootstrap.css";
import "assets/css/index.scss";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

import store from "reduxStore/reducers";
import "./i18n";

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
// <Provider store={store}>
//   <BrowserRouter>
//     <App/>
//   </BrowserRouter>
// </Provider>

// ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   </React.StrictMode>,
//   document.getElementById("root")
// );

root.render(
      <Provider store={store}>
      <App />
    </Provider>
)


// import { createRoot } from 'react-dom/client';
// const container = document.getElementById('app');
// const root = createRoot(container); // createRoot(container!) if you use TypeScript
// root.render(<App tab="home" />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
