import {Badger} from "./BrowserNotification";
import i18next from "i18next";

export const showNewFavicon = (type = "", text = "Новое сообщение") => {

  // const showNewFaviconLocal = ()=>{
  //   console.log("test favicon")
  //   showNewFavicon("default", "old text")
  // }
  // window.addEventListener("focus",  showNewFaviconLocal);
  // return () => {
  //   window.removeEventListener("focus",  showNewFaviconLocal)


  // alert("test", type)
  console.log("type", type)
  console.log("window", window)
  let newTxt = text;
  let link = document.querySelector("link[rel*='shortcut icon']") || document.createElement('link');
  link.type = 'image/png';
  link.rel = 'shortcut icon';
  document.title = newTxt;
  // if (!document.hasFocus()) {
  //   link.href = '/favicon.ico';
  // } else {
  link.href = type === "notification" ? `${process.env.PUBLIC_URL}/favicon1.ico` : `${process.env.PUBLIC_URL}/favicon.ico`;

}

const addDotToTab = () => {
  // const myBadgerOptions = {}; // See: constructor for customization options
  // const myBadger = new Badger(myBadgerOptions);
  // console.log("myBadger", myBadger)
  // // Live update value example:
  // myBadger.value = value;

  let newTxt = "Новое сообщение";
  let oldTxt = document.title;

  let link = document.querySelector("link[rel*='shortcut icon']") || document.createElement('link');
  link.type = 'image/png';
  link.rel = 'shortcut icon';

  function migalka() {
    if (count_alert_message.text() >= 1) {

      if (document.title === oldTxt) {
        document.title = newTxt;
        link.href = '/favicon1.png';
      } else {
        document.title = oldTxt;
        link.href = '/favicon.png';
      }
    } else {
      document.title = oldTxt;
      link.href = '/favicon.png';
    }
    document.getElementsByTagName('head')[0].appendChild(link);
  }
}


export function notifyMe(data = "", id = "") {
  if (!("Notification" in window)) {
    // Check if the browser supports notifications
    alert("This browser does not support desktop notification");
  } else if (Notification.permission === "granted") {
    console.log("Notification1", Notification.permission)
    // Check whether notification permissions have already been granted;
    // if so, create a notification
    let body = `${i18next.t("PaymentNotificationHTMLTitle")} ${data}`;
    const notification = new Notification("Payment notification", {
      tag: id,
      icon: "/favicon.ico",
      requireInteraction: true,
      body: body
    });
    // addDotToTab(data)
    showNewFavicon("notification", i18next.t("PaymentNotificationHTMLTitle"));
    // …
  } else if (Notification.permission !== "denied") {
    console.log("Notification2", Notification)

    // We need to ask the user for permission
    Notification.requestPermission().then((permission) => {
      // If the user accepts, let's create a notification
      if (permission === "granted") {
        const notification = new Notification(i18next.t("PaymentNotificationHTMLTitle") + "2" + data, {tag: id});
        // addDotToTab(data)
        showNewFavicon("notification", i18next.t("PaymentNotificationHTMLTitle"));
        // …
      }
    });
  }

  // At last, if the user has denied notifications, and you
  // want to be respectful there is no need to bother them anymore.
}

