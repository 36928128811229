import React, {useCallback, useEffect, useState} from "react";
import Modal from "./Modal";
import {useFormik} from "formik";
import * as Yup from "yup";
import swal from "sweetalert";

import FormikField from "../formComponents/FormikField";
import FormikTextarea from "../formComponents/FormikTextarea";
import api from "../../utils/api";
import FormikSelect from "../formComponents/FormikSelect";
import {loadTableData} from "../../redux/reducers/tableData";
import {useDispatch, useSelector} from "react-redux";
import {Load} from "../load/Load";
import {useTranslation} from "react-i18next";
import i18n from "i18next";

const ModalEditRequestFB = ({data, show, closeCallback, disabled = false}) => {

  const [hasErrorAdvName, setHasErrorAdvName] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [timezoneOptions, setTimezoneOptions] = useState([]);
  const {isLoad} = useSelector((state) => state.tableData);
  const [countriesOptions, setCountriesOptions] = useState([]);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const lang = i18n.language;

  useEffect(() => {
    formik.setSubmitting(true);
    // api("/facebook/business-categories", {method: "GET"})
    //   .then(response => {
    //     setBusinessCategoriesOptions(businessCategoriesFormatter(response));
    //     return response
    //   })
    //   .then((businessCategories) => {
    //     businessCategories &&
        api("/time-zone/all").then((res) => {
          let newTimezones = res.map(timezone => {
            return {
              id: timezone.id,
              name: timezone.name,
              hour: timezone.hour,
              textForCopy: timezone.hour + " " + timezone.name,
              value: `${timezone.hour}_${timezone.name}`,
              label: <div
                className={"d-flex justify-content-between"}
              >
                <div>{timezone.hour}</div>
                <div>{timezone.name}</div>
              </div>
            }
          });
          setTimezoneOptions(newTimezones);
          api(`/country/${lang}/all`, {method: "GET"})
            .then((res => {
              setCountriesOptions(res);
            }))

        })
      .finally(() => formik.setSubmitting(false));
  }, []);


  const formik = useFormik({
    initialValues: {
      hasError: false,
      customerCenterLogin: data.customerCenterLogin || "",
      ownerLogin: data.ownerLogin || "",
      legalUnp: data.taxId || "",
      // legalForm: data.legalForm || "",
      payLineName: data.payLineName || "",
      countryValue: data?.legalPayLineAddress?.country || "",
      legalAddress: data?.legalPayLineAddress?.address || "",
      managersIds: data.managersIds || "",
      linkToAdvertisingPage: data.linkToAdvertisingPage || "",
      accountName: data.accountName || "",
      appLink: data.appLink || "",
      lastEditDate: data.lastEditDate || "",
      creationDate: data.creationDate || "",
      finalAdvertiser: data ? {
        value: data?.finalAdvertiser === null || data?.finalAdvertiser === true,
        label: (data?.finalAdvertiser === null || data?.finalAdvertiser === true) ? t("yes") : t("no")
      } : null,
      nameFinalAdvertiser: data?.finaleAdvertiser?.nameFinalAdvertiser || "",
      advertiserCountry: data?.finaleAdvertiser?.advertiserCountry ? data.finaleAdvertiser?.advertiserCountry : null,
      advertiserUnp: data?.finaleAdvertiser?.advertiserUnp || "",
      advertiserAddress: data?.finaleAdvertiser?.advertiserAddress || "",
      advertiserTypeOfOwnership: data?.finaleAdvertiser?.advertiserTypeOfOwnership || "",
      site: data.site || "",
      adwName: data.adwName || "",
      comment: data?.comment?.message || "",
      timezoneId: {
        value: `${data?.timeZoneResponse.name}_${data?.timeZoneResponse.hour}`,
        id: data?.timeZoneResponse?.id,
        name: data?.timeZoneResponse.name,
        hour: data?.timeZoneResponse.hour,
        textForCopy: `${data?.timeZoneResponse.hour} ${data?.timeZoneResponse.name}`,
        label: <div className="d-flex justify-content-between pr-2">
          <div>{data?.timeZoneResponse.hour}</div>
          <div>{data?.timeZoneResponse.name}</div>
        </div>
      }
    },
    validationSchema: Yup.object({
      hasError: Yup.mixed().test({
        test: (value) => {
          return !value;
        }
      }),
      customerCenterLogin: Yup.string().required(t("validation.required")),
      ownerLogin: Yup.string().required(t("validation.required")),
      linkToAdvertisingPage: Yup.string()
        .max(250, t("validation.notMore", {number: 250}))
        .matches(
          /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi,
          // /((https?):\/\/)?(www.)?[a-zA-ZА-Яа-я0-9-_]+(\.[a-zA-ZА-Яа-я-_]{2,}){1,3}(#?\/?[a-zA-ZА-Яа-я0-9-_#]+)*\/?(\?[a-zA-ZА-Яа-я0-9-_]+=[a-zA-ZА-Яа-я0-9-_%]+&?)?$/,
          t("validation.correctURL")
        ).required(t("validation.required")),
      adwName: Yup.string().max(150, t("validation.notMore", {number: 150})),
      accountName: Yup.string()
        .min(3, t("validation.noLess", {number: 3}))
        .max(100, t("validation.notMore", {number: 100}))
        .required(t("validation.required")),
      managersIds: Yup.string()
        .max(250, t("validation.notMore", {number: 250}))
        .matches(/^[0-9, ;]*$/i, t("validation.managersIdValid"))
        .required(t("validation.required")),
      appLink: Yup.string()
        .max(250, t("validation.notMore", {number: 250})),
      site: Yup.string()
        .max(250, t("validation.notMore", {number: 250}))
        .matches(
          /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi,
          // /((https?):\/\/)?(www.)?[a-zA-ZА-Яа-я0-9-_]+(\.[a-zA-ZА-Яа-я-_]{2,}){1,3}(#?\/?[a-zA-ZА-Яа-я0-9-_#]+)*\/?(\?[a-zA-ZА-Яа-я0-9-_]+=[a-zA-ZА-Яа-я0-9-_%]+&?)?$/,
          t("validation.correctURL")
        ),
      finalAdvertiser: Yup.object().test("testFinalAdvertiser", t("validation.required"), function (val) {
        return val && val.label
      }).nullable(),
      nameFinalAdvertiser: Yup.string()
        .matches(/^[A-Za-zА-Яа-я0-9]*$/, t("validation.enterNotCorrect"))
        .max(20, t("validation.notMore", {number: 20}))
        .test("testNameFinalAdvertiser", t("validation.required"), function (val) {
          if (this.parent?.finalAdvertiser?.value) {
            return true
          }
          return val;
        }),
      advertiserCountry: Yup.object().test("testAdvertiserCountry", t("validation.required"), function (val) {
        if (this.parent?.finalAdvertiser?.value) {
          return true
        }
        return val && val.label
      }).nullable(),
      advertiserUnp: Yup.string()
        .matches(/^[0-9]*$/, t("validation.enterNumber"))
        .max(20, t("validation.notMore", {number: 20}))
        .test("testAdvertiserUnp", t("validation.required"), function (val) {
          if (this.parent?.finalAdvertiser?.value) {
            return true
          }
          return val;
        }),
      advertiserAddress: Yup.string()
        .matches(/^[A-Za-zА-Яа-я0-9]*$/, t("validation.enterNotCorrect"))
        .max(20, t("validation.notMore", {number: 20}))
        .test("testAdvertiserAddress", t("validation.required"), function (val) {
          if (this.parent?.finalAdvertiser?.value) {
            return true
          }
          return val;
        }),
      advertiserTypeOfOwnership: Yup.string()
        .matches(/^[A-Za-zА-Яа-я0-9 ]*$/, t("validation.enterNotCorrect"))
        .max(20, t("validation.notMore", {number: 20}))
        .test("testAdvertiserTypeOfOwnership", t("validation.required"), function (val) {
          if (this.parent?.finalAdvertiser?.value) {
            return true
          }
          return val;
        }),
    }),
    onSubmit: () => {
      swal({
        icon: "warning",
        text: t("confirmSaveDate"),
        buttons: [t("no"), t("yes")],
        className: "confirm-simple-modal"
      }).then((response) => {
        if (response) {
          let bodyForFb = {
            accountName: formik.values.accountName,
            advName: formik.values.adwName,
            appLink: formik.values.appLink,
            comment: data?.comment ? {
              id: data?.comment?.id,
              message: formik.values?.comment,
              statusRead: false
            } : null,
            id: data?.id,
            linkToAdvertisingPage: formik.values.linkToAdvertisingPage,
            managersIds: formik.values.managersIds,
            ownerId: data.ownerId,
            site: formik.values.site,
            status: data.status,
            timeZoneId: formik.values.timezoneId?.id,
            finalAdvertiser: formik.values?.finalAdvertiser?.value,
            finaleAdvertiser: !formik.values?.finalAdvertiser?.value ? {
              advertiserAddress: formik.values?.advertiserAddress || "",
              advertiserTypeOfOwnership: formik.values?.advertiserTypeOfOwnership || "",
              advertiserUnp: formik.values?.advertiserUnp || "",
              countryId: formik.values?.advertiserCountry?.value || "",
              id: data ? data?.finaleAdvertiser?.id : null,
              nameFinalAdvertiser: formik.values?.nameFinalAdvertiser || "",
            } : null,
          }
          api("/facebook/registration/update", {
            method: "POST",
            data: bodyForFb
          })
            .then((response) => {
              if (response) {
                dispatch(loadTableData("requestFB"));
                closeCallback();
                swal({
                  icon: "success",
                  text: t("saveSuccess")
                })
              }
            })
        }
      }).finally(() => formik.setSubmitting(false));
    },
  });


  const onHandelBlur = useCallback(async (value, name) => {
    formik.setFieldTouched(name, true);
    value === "" && setHasErrorAdvName("");
    value === "" && setSuccessMessage("");
    if (value !== "") {
      api(`/ad-account/check-adv-name`,
        {
          method: "POST",
          data: {
            advName: value,
            serviceType: 3,
          }
        }).then(response => {
        response.example && formik.setFieldValue(`hasError`, true);
        response.example && setHasErrorAdvName(t("advNameError", {
          value: value,
          example: response.example
        }));
        response.example && setSuccessMessage("");
        !response.example && formik.setFieldValue(`hasError`, false);
        !response.example && setSuccessMessage(t("advNameIsUniq"));
        !response.example && setHasErrorAdvName("");
      })
    }
  }, [formik]);

  return (
    <>
      <Modal
        show={show}
        notCloseOnOverlay={true}
        closeCallback={closeCallback}
        title={`${disabled ? t("watchingRequestTitle", {service: "Facebook"}) : t("editRequestTitle", {service: "Facebook"})}`}>
        {(formik.isSubmitting) && <Load/>}
        <form
          onSubmit={formik.handleSubmit}
          className="row px-3"
          style={{width: "440px"}}
        >
          {isLoad && <Load/>}
          <div className="w-100">
            <label className="">
              {t("centerClient")} ({t("email")})
            </label>
            <FormikField
              name="customerCenterLogin"
              disabled={true}
              formik={formik}
              className="pb-3 pl-0 position-relative"
            />
          </div>

          <div className="w-100">
            <label className="">
              {t("client")} ({t("email")})
            </label>
            <FormikField
              name="ownerLogin"
              disabled={true}
              formik={formik}
              className="pb-3 pl-0 position-relative"
            />
          </div>
          <div className="w-100">
            <label className="">
              {t("paylineName")}
            </label>
            <FormikField
              name="payLineName"
              disabled={true}
              formik={formik}
              className="pb-3 pl-0 position-relative"
            />
          </div>
          {/*<div className="w-100">*/}
          {/*  <label className="">*/}
          {/*    {t("legalForm")}*/}
          {/*  </label>*/}
          {/*  <FormikField*/}
          {/*    name="legalForm"*/}
          {/*    disabled={true}*/}
          {/*    formik={formik}*/}
          {/*    className="pb-3 pl-0 position-relative"*/}
          {/*  />*/}
          {/*</div>*/}
          <div className="w-100">
            <label className="">
              {t("legalUnp")}
            </label>
            <FormikField
              name="legalUnp"
              disabled={true}
              formik={formik}
              className="pb-3 pl-0 position-relative"
            />
          </div>
          <div className="w-100">
            <label className="">
              {t("country")}
            </label>
            <FormikField
              name="countryValue"
              disabled={true}
              formik={formik}
              className="pb-3 pl-0 position-relative"
            />
          </div>
          <div className="w-100">
            <label className="">
              {t("legalAddress")}
            </label>
            <FormikTextarea
              name="legalAddress"
              disabled={true}
              formik={formik}
              className="pb-3 pl-0 position-relative"
            />
          </div>
          <div className="w-100">
            <label className="">
              {t("requestCreationDate")}
            </label>
            <FormikField
              name="creationDate"
              disabled={true}
              formik={formik}
              className="pb-3 pl-0 position-relative"
            />
          </div>

          <div className="w-100">
            <label className="">
              {t("requestLastEditingDate")}
            </label>
            <FormikField
              name="lastEditDate"
              disabled={true}
              formik={formik}
              className="pb-3 pl-0 position-relative"
            />
          </div>
          <div className="w-100">
            <label className="">
              {t("advName")}
            </label>
            <div className={"position-relative pb-3"}>
              <input
                className={`form-control ${hasErrorAdvName ? "border-danger" : ""}`}
                name={"adwName"}
                value={formik.values["adwName"]}
                onBlur={(e) => onHandelBlur(e.target.value, `adwName`)}
                // onBlur={(e) => console.log(e.target.value)}
                onChange={(e) => formik.setFieldValue(`adwName`, e.target.value)}
              />
              {hasErrorAdvName && <div className={"text-danger position-relative"}>{hasErrorAdvName}</div>}
              {successMessage && <div className={"text-orange position-relative"}>{successMessage}</div>}
            </div>
          </div>
          <div className="w-100">
            <label className="">
              Business Manager ID
            </label>
            <FormikField
              name="managersIds"
              disabled={disabled}
              formik={formik}
              className="pb-3 pl-0 position-relative"
            />
          </div>
          <div className="w-100">
            <label className="">
              {t("linkToAdvertisingPage")}
            </label>
            <FormikField
              name="linkToAdvertisingPage"
              disabled={disabled}
              formik={formik}
              className="pb-3 pl-0 position-relative"
            />
          </div>
          <div className="w-100">
            <label className="">
              {t("timezone")}
            </label>
            <FormikSelect
              name="timezoneId"
              textForCopy={disabled ? "" : formik.values["timezoneId"]?.value?.split("_").join(" ") || ""}
              disabled={disabled}
              formik={formik}
              className="pb-3 pl-0 position-relative"
              options={timezoneOptions}
            />
          </div>
          <div className="w-100">
            <label className="">
              {t("userAccountName")}
            </label>
            <FormikField
              name="accountName"
              disabled={disabled}
              formik={formik}
              className="pb-3 pl-0 position-relative"
            />
          </div>

          <div className="w-100">
            <label className="">
              {t("site")}
            </label>
            <FormikField
              name="site"
              formik={formik}
              disabled={disabled}
              className="pb-3 pl-0 position-relative"
            />
          </div>

          <div className="w-100">
            <label className="">
              {t("appLink")}
            </label>
            <FormikField
              name="appLink"
              formik={formik}
              disabled={disabled}
              className="pb-3 pl-0 position-relative"
            />
          </div>

          <div className={"w-100"}>
            <label className="">{t("finalAdvertiser")}</label>
            <FormikSelect
              formik={formik}
              disabled={disabled}
              name={"finalAdvertiser"}
              value={formik.values["finalAdvertiser"]}
              className={"pb-3 pl-0 position-relative"}
              options={[
                {value: false, label: t("no")},
                {value: true, label: t("yes")},
              ]}/>
          </div>
          {formik.values?.finalAdvertiser?.value === false ? <>
            <div className="w-100">
              <label className="">{t("nameFinalAdvertiser")}</label>
              <FormikField
                name="nameFinalAdvertiser"
                formik={formik}
                disabled={disabled}
                className="pb-3 pl-0 position-relative"
              />
            </div>

            <div className={"w-100"}>
              <label className="">{t("advertiserCountry")}</label>
              <FormikSelect
                formik={formik}
                disabled={disabled}
                name={"advertiserCountry"}
                value={formik.values["advertiserCountry"]}
                className={"pb-3 pl-0 position-relative"}
                options={countriesOptions}/>
            </div>
            <div className="w-100">
              <label className="">{t("advertiserUnp")}</label>
              <FormikField
                name="advertiserUnp"
                formik={formik}
                disabled={disabled}
                className="pb-3 pl-0 position-relative"
              />
            </div>
            <div className="w-100">
              <label className="">{t("advertiserAddress")}</label>
              <FormikField
                name="advertiserAddress"
                formik={formik}
                disabled={disabled}
                className="pb-3 pl-0 position-relative"
              />
            </div>
            <div className="w-100">
              <label className="">{t("advertiserTypeOfOwnership")}</label>
              <FormikField
                name="advertiserTypeOfOwnership"
                formik={formik}
                disabled={disabled}
                className="pb-3 pl-0 position-relative"
              />
            </div>
          </> : null}

          <div className="w-100">
            <label className="">
              {t("comment")}
            </label>
            <FormikTextarea
              name="comment"
              formik={formik}
              disabled={disabled}
              className="pb-3 pl-0 position-relative"
            />
          </div>

          {!disabled && <div className={"w-100 text-center"}>
            <button
              type={"submit"}
              disabled={formik.isSubmitting}
              className={"btn btn-primary mr-3"}>
              {t("save")}
            </button>
            <button
              className={"btn btn-outline-primary"}
              type={"button"}
              onClick={() => closeCallback()}
            >{t("cancel")}
            </button>
          </div>}
        </form>

      </Modal>
    </>
  )
};

export default ModalEditRequestFB