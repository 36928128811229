import React, {useEffect, useState} from "react";
import {Tooltip} from "react-tooltip";

const Tooltips = ({
                    text = "",
                    children,
                    delayShow = 500,
                    tooltipId,
                    place = "bottom",
                    html = null,
                    className = "tooltip_icon"
                  }) => {
  const [tooltipText, setTooltipText] = useState(text);

  useEffect(() => {
    setTooltipText(text)
  }, [text])

  return (
    <>
      <div
        data-tooltip-id={tooltipId}
        className={className}
      >
        {children}
      </div>
      <Tooltip
        id={tooltipId}
        border={"1px solid #C9C9C9"}
        variant={'light'}
        content={tooltipText}
        html={html}
        place={place}
        effect='solid'
        className={"simple_tooltip_text"}
        delayShow={delayShow}
        // closeOnEsc={true}
        data-border-class={"border"}
        eventOff={"mouseleave mouseout scroll mousewheel blur"}
      />

    </>
  )
}

export default Tooltips;