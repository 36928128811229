import * as Yup from "yup";
import {isValidPhoneNumber} from "react-phone-number-input";
import {validationSum} from "../utils/sumValidation";
import i18next from "i18next";

export const validationSchemaAddGeneralCommission = (t) => {
  return (
    Yup.object().shape({
      commission: Yup.number()
        .positive(t("validation.positiveNumber"))
        .typeError(t("validation.enterNumber"))
        .required(t("validation.required"))
        .max(100, t("validation.notMore", {number: 100}))
        .test("testCommission", t("validation.twoSymbolAfterComma"), function (val) {
          return val && validationSum(val)
        }),
      serviceId: Yup.object()
        .typeError(t("validation.required"))
        .required(t("validation.required")),
      countryId: Yup.object()
        .typeError(t("validation.required"))
        .required(t("validation.required")),
    }))
};

export const validationSchemaAddIndividualCommission = (t) => Yup.object().shape({
  commission: Yup.number()
    .positive(t("validation.positiveNumber"))
    .typeError(t("validation.enterNumber"))
    .required(t("validation.required"))
    .max(100, t("validation.notMore", {number: 100}))
    .test("testCommission", t("validation.twoSymbolAfterComma"), function (val) {
      return val && validationSum(val)
    }),
  cabinetId: Yup.object()
    .typeError(t("validation.required"))
    .required(t("validation.required")),
  serviceId: Yup.object()
    .typeError(t("validation.required"))
    .required(t("validation.required")),
});

export const emailValidation = Yup.string()
  .max(250, i18next.t("validation.notMore", {number: 250}))
  .email(i18next.t("validation.incorrectEmailt"));

export const passwordValidation = (t) => Yup.string()
  .min(8, t("validation.noLess", {number: "8"}))
  .matches(
    /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/, t("validation.validPassword"));

export const phoneValid = (t)=>Yup.string()
  .test("testPhone", t("validation.incorrectPhone"), function (value) {
    // console.log("value", value, this.parent)
      if (value) {
        return isValidPhoneNumber(value);
      } else {
        return true
      }
    }
  );

export const validPercentValue = (t, min = 0, max = 99) => Yup.number()
  .positive(t("validation.positiveNumber"))
  .typeError(t("validation.enterNumber"))
  .required(t("validation.required"))
  .max(max, t("validation.notMore", {number: max}))
  .min(min, t("validation.noLessShort", {number: min}))
  .test("testCommission", t("validation.twoSymbolAfterComma"), function (val) {
    return val && validationSum(val)
  })

