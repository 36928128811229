export const apiUrls = Object.freeze({
  clients: "/clients/get-all-clients",
  clientsActivity: "/clients/client-summaries",
  exchangeRateReport: `/currency/history?currencyFrom=:currencyFrom&currencyTo=:currencyTo&dateFrom=:dateFrom&dateTo=:dateTo`,
  getTikTokRequests: "/tik-tok/registration/all",
  TikTokUpdateRegistration: "/tik-tok/registration/update",
  changeTikTokStatus: "/tik-tok/registration/change-status/:id/:status",
  deleteTikTokRequest: "/tik-tok/registration/delete/:id",
  createTikTokAcc: "/tik-tok/registration/create/:id",
  giveAccessToTTAcc: "/tik-tok/registration/create-finish/:id",
  getScreeningList: "/screening/get",
  getScreeningByUserId: "/screening/get/:userId",
  getFilesForScreening: "/screening/load/file/:fileName/:userId",
  updateScreeningStatus: "/screening/update",
  getSalesClients: "/sales",
  salesManualReplenish: "/sales/replenish",
  getPaymentsList: "/payment/dashboard/payments/all",
  getInvoicesList: "/payment/dashboard/invoices/all",
  setPaymentsComment: "/payment/dashboard/payment/comment/:id",
  getInvoiceById: "/payment/dashboard/invoices/short/:id",
  getPaymentsById: "/payment/dashboard/payments/all/short/:id",
  linkedPaymentInvoice: "/payment/dashboard/link",
  paymentRefresh: "payment/dashboard/payment/refresh/:id",
  paymentInvoiceHide: "/payment/dashboard/hide/",
  changeEmailUrl: "/clients/provide-client-access",
  setInvoice: "/payment/invoice/create",
  getPayLineList: "/pay-line",
  payLineApprove: "/pay-line/approve/center-client/:id",
  payLineApproveClient: "/pay-line/approve/client",
  payLineApproveOld: "/pay-line/approve/:id",
  payLineReject: "/pay-line/reject/:id",
  getPLById: "/pay-line/:id",
  updatePL: "/pay-line/update/:id",
  getResetRequests: "/pay-line-to-reset",
  approveResetPL: "/pay-line-to-reset/reset/:id",
  toServiceAccess: "/cabinet-services/to-service-access",
  getClientServiceAccess: "/cabinet-services/to-service-access/:id",
  rejectResetPL: "/pay-line-to-reset/reject/:id",
  getMiddleMcc: "/google/mcc/middle-mcc",
  createMcc: "/google/mcc/add",
  getAvailableMcc: "/google/mcc/available",
  getAssignedMcc: "google/mcc/assigned",
  closeWebsocket: "/websocket/close-session/:sessionId",
  getClientTag: "/tags/list",
  getPartnerProgramRequestsList: "/partner_program/request",
  getPartnerProgramClientsList: "/partner_program/clients",
  changePartnerProgramStatus: "/partner_program/request/update",
  setPermissionsCC: "/pay-line/consent/:id",
  changeStatusPL: "/pay-line/checking/:id",
  getCommissionsForClient: "/commissions/:id"
});

export const appUrls = Object.freeze({
  screening: "/screening",
  screeningId: "/screening/:id",
  requestFB: "/requestFB",
  requestTikTok: "/requestTikTok",
  paymentInfo: "/payment_info",
  users: "/users",
  providers: "/providers",
  finApplications: "/fin-applications",
  payers: "/payers",
  payersId: "/payers/:id",
  home: "/",
  partnerProgramClients: "/partnership-program/clients",
  partnerProgramRequests: "/partnership-program/requests",
  adAccount: "/addAccount",
  accountsActive: "/accounts-active",
  createClient: "/create-client",
  clientExpenses: "/client/clientId=:id/expenses",
  clientPayLine: "/client/clientId=:id/payline",
  clientAccounts: "/client/clientId=:id/accounts",
  clientSettings: "/client/clientId=:id/settings",
  clientInfo: "/client/clientId=:id/info",
  clientFinMovement: "/client/clientId=:id/finMovement",
  clientContracts: "/client/clientId=:id/contracts",
  clientFinDocs: "/client/clientId=:id/finDoc",
  clientProfitability: "/client/clientId=:id/profitability",
  clientClientsPage: "/client/clientId=:id/clients",
  clientFinSettings: "/client/clientId=:id/finSettings",
  reports: "/reports",
  reportsRate: "/reports/rate",
  googleMcc: "/mccGoogleAds",
  manualPayment: "/manual-payment",
  manualDeduct: "/manual-deduct",
  penalty: "/penalty",
  teams: "/teams",
  googleTax: "/google-tax",
  invoicePayments: "/invoice_payments",
  system: "/system",
  commissionGeneral: "/commissions/general",
  commissionIndividual: "/commissions/individual",

})