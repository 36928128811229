import { createSlice } from "@reduxjs/toolkit";

export const toggleSidebar = createSlice({
  name: "toggleSidebar",
  initialState: {
    isOpen: true,
  },
  reducers: {
    setToggleSidebar: (state, { payload }) => {
      return { ...payload };
    },
  },
});

export const { setToggleSidebar } = toggleSidebar.actions;

export const getToggleSidebar = (state) => state.toggleSidebar;

export default toggleSidebar.reducer;
