import React from "react";

export default function FormikField({
                                      className,
                                      name,
                                      type,
                                      onChange = null,
                                      onBlur = null,
                                      formik,
                                      disabled = false,
                                      isArr = false,
                                      arr = [],
                                      style = {},
                                      autoComplete,
                                      customError = null,
                                    },) {

  const isArrError = isArr && formik.touched
    && formik.touched[arr.fieldType]
    && formik.touched[arr.fieldType][arr.index]
    && formik.touched[arr.fieldType][arr.index][arr.fieldName]
    && formik.errors[arr.fieldType]
    && formik.errors[arr.fieldType][arr.index]
    && formik.errors[arr.fieldType][arr.index][arr.fieldName];

  const classNameForInput = (isArr) => {
    if (!isArr) {
      return `${(formik.touched[name] && formik.errors[name]) || (customError && !customError.isValid && customError.message) ? "border-danger" : ""}`
    } else {
      return `${isArrError ? "border-danger" : ""}`
    }
  }

  const showError = (isArr) => {
    if (!isArr) {
      return (
          (formik.touched[name] && formik.errors[name])
          || (customError && !customError.isValid && customError.message))
        && (<div className="text-danger">{formik.errors[name] || customError.message}</div>)
    } else {
      return isArrError && (
        <div className="text-danger">{formik.errors[arr.fieldType][arr.index][arr.fieldName]}</div>
      )
    }
  }

  const valueForInput = isArr ? formik.values[arr.fieldType][arr.index][arr.fieldName] : (formik.values[name]);

  // console.log("hasErrorAdvName", hasErrorAdvName);
  // console.log("formik", formik.errors);

  return (
    <div className={`${className} position-relative pb-3`}>
      <input
        {...formik.getFieldProps(name)}
        style={style}
        id={name}
        name={name}
        autoComplete={autoComplete ? autoComplete : "on"}
        disabled={disabled}
        onChange={(e) => {
          onChange ? onChange(e.target.value) : formik.setFieldValue(name, e.target.value)
        }}
        onBlur={(e) => {
          onBlur ? onBlur(e.target.value) : formik.setFieldTouched(name)
        }}
        type={type || "text"}
        className={`form-control ${classNameForInput(isArr)}`
        }

        value={valueForInput}
      />
      <> {showError(isArr)} </>

    </div>
  );
}
