import React from "react";
import {Route, Switch} from 'react-router-dom';
import {Redirect, useLocation} from "react-router";
import {Load} from "../components/load/Load";

const ProtectedRoutes = ({isAuth, right, ROUTES, authInProcess = false}) => {
  const location = useLocation();
  if (authInProcess) {
    return <Load/>
  }
  if (!isAuth ) {
    return <Redirect to={`/login`}/>
  }

  return (
    <Switch>
      {ROUTES.map(({component: Component, path, rights, exact}, index) => {
          if (rights.includes(right)) {
            return (
              <Route
                path={`${path}`}
                key={index}
                exact={exact}
              >
                <Component/>
              </Route>
            )
          }
        }
      )}
    </Switch>
  )
}

export default ProtectedRoutes;
