import React from "react";
import Modal from "./Modal";
import {useFormik} from "formik";
import FormikTextarea from "../formComponents/FormikTextarea";
import * as Yup from "yup";
import api from "../../utils/api";
import swal from "sweetalert";
import {loadTableData} from "reduxStore/reducers/tableData";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";

const ModalRevisionFBAcc = ({show, closeCallback, data}) => {

  const dispatch = useDispatch();
  const {t}=useTranslation();

  const formik = useFormik({
    initialValues: {
      comment: (data.comment && data.comment.message) || "",
    },
    validationSchema: Yup.object({
      comment: Yup.string().max(250, t("validation.notMore", {number: 250})).required(t("validation.required"))
    }),
    onSubmit: ({comment}) => {
      api(`/facebook/registration/status/revision/${data.id}`, {
        method: "POST",
        data: {
          comment
        }
      })
        .then((response) => {
          if (response) {
            dispatch(loadTableData("requestFB"));
            closeCallback();
            swal({
              icon: "success",
              text: t("requestForRevisionSuccess")
            })
          }
        }).finally(() => formik.setSubmitting(false));
    }
  })
  return (
    <Modal
      show={show}
      className={""}
      notCloseOnOverlay={true}
      closeCallback={closeCallback}
      title={t("commentForClient")}
    >
      <form
        onSubmit={formik.handleSubmit}
        className="row p-3 pb-0"
        style={{width: "440px"}}
      >
        <div className="w-100">
          <label htmlFor="attempts" className="">
            {t("comment")}
          </label>
          <FormikTextarea
            name="comment"
            height={"100px"}
            formik={formik}
            className="pb-3 pl-0 position-relative"
          />
          <div className={"text-center"}>
            <button type={"submit"} className={"btn btn-primary mr-3"}>{t("send")}</button>
            <button
              className={"btn btn-outline-primary"}
              onClick={() => {
                formik.resetForm();
                closeCallback();
              }}
            >{t("cancel")}
            </button>
          </div>
        </div>
      </form>

    </Modal>
  )
}

export default ModalRevisionFBAcc