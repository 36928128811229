import React from 'react';
import {Select} from "../Select";

const FormikSelect = ({textForCopy, formik, name, className, onChange, options, disabled = false, title, noDataText = ""}) => {
  return (
    <>
      {title && <label className={"text-muted"}>{title}</label>}
      <div className={`${className} ${formik.touched[name] && formik.errors[name] ? "border-danger" : ""}`}>
        <Select
          textForCopy={textForCopy}
          name={name}
          disabled={disabled}
          onChange={(e) => {
            formik.setFieldValue(name, e);
            onChange && onChange(e);
          }}
          onBlur={() => formik.setFieldTouched(name, true)}
          value={formik.values[name] || {label: "", value: ""}}
          options={options || []}
          noDataText={noDataText || ""}
        />
        {formik.touched[name] && formik.errors[name] ? (
          <div className="text-danger">{formik.errors[name]}</div>
        ) : null}

      </div>
    </>
  )
}

export default FormikSelect;