import React from "react";
import "assets/css/pagination.scss";
import Arrow from "assets/images/arrow.svg?svgr"

export default function Pagination({
                                     pageIndex,
                                     canPreviousPage,
                                     previousPage,
                                     gotoPage,
                                     pageCount,
                                     canNextPage,
                                     nextPage
                                   }) {

  const ShowItem = ({label, to, disabled = false}) => {
    return (
      <li className={`pagination-item ${pageIndex === to ? "active" : null}`}>
        <button
          disabled={disabled}
          onClick={() => gotoPage(to)}
        >{label}
        </button>
      </li>
    )
  }
  return (

    <nav>
      <ul className="pagination mb-0">
        <li className={"pagination-item"}>
          <button
            disabled={!canPreviousPage}
            onClick={previousPage}>
            <Arrow style={{transform: "rotate(-90deg)"}} stroke={"black"} />

          </button>
        </li>

        {pageIndex >= 2 && <ShowItem label={pageIndex - 1} to={pageIndex - 2}/>}
        {pageIndex >= 1 && <ShowItem label={pageIndex} to={pageIndex - 1}/>}
        <ShowItem label={pageIndex + 1} to={pageIndex}/>
        {pageIndex + 1 <= pageCount - 1 && <ShowItem label={pageIndex + 2} to={pageIndex + 1}/>}
        {pageIndex + 2 <= pageCount - 1 && <ShowItem label={pageIndex + 3} to={pageIndex + 2}/>}

        <li className={"pagination-item"}>
          <button
            disabled={!canNextPage}
            onClick={() => {
              nextPage()
            }}>
            <Arrow style={{transform: "rotate(90deg)"}} stroke={"black"} />
          </button>
        </li>
      </ul>
    </nav>
  );
}