import React from "react";
import image from "./images/preloader.svg";
import "./Load.css";

export const Load = ({isModal = false, className}) => {


  return (!isModal ? <div className={`loadingElement ${className}`}>
      <img src={image} className={"loadingImage"} alt={"Идёт загрузка..."}/>

    </div> :
    <div className={"load-modal"}>
      <div className={"load-modal-body"}>
        <img src={image} alt={"Идёт загрузка..."}/>
        <h3 className={"load-modal-title"}>Идёт обработка операции!</h3>
        <p className={"load-modal-text"}>Это может занять несколько секунд.
          Дождитесь ответа.</p>
      </div>


    </div>)

};
