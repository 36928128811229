import {apiRedux} from "../reducers/apiRedux";
import {apiUrls} from "../../constants/appUrls";

const PaymentInvoiceApi = apiRedux.injectEndpoints({
  endpoints: (build) => ({
    closeSocketSession: build.query({
      query: (data) => ({
        url: apiUrls.closeWebsocket.formatURL({sessionId:data})
      }),
    }),
  })
});

export const UseCloseSocketSession = PaymentInvoiceApi.useLazyCloseSocketSessionQuery;
