import React from 'react';
import EmptySearch from "assets/images/EmptyImg.svg?svgr";
const EmptyTableRow = ({text, title}) => {
  return (
    <div className={"d-flex justify-content-center flex-column align-items-center py-5 bg-white px-3"}>
      <EmptySearch width={44} height={44}/>
      <div className={"text-lg pt-4 pb-3"}>{title}</div>
      <div className={"text-sm text-light"}>{text}</div>
    </div>
  );
};

export default EmptyTableRow;