import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { login } from "reduxStore/reducers/auth";
import Logo from "assets/images/logo.svg";
import FormikField from "components/formComponents/FormikField";
import { useFormik } from "formik";
import * as Yup from "yup";
import {passwordValidation} from "../constants/validationSchemas";
import {useTranslation} from "react-i18next";

export default function Login() {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [showPsw, setShowPsw] = useState(false);
  const {
    message,
    login: loginEmail,
    password,
  } = useSelector((state) => state.auth);

  const SignInSchema = Yup.object().shape({
    email: Yup.string()
      .max(250, t("validation.notMore", {number: 250}))
      .email(t("validation.incorrectEmail")).required(t("validation.required")),
    password: passwordValidation(t).required(t("validation.required")),
  });

  const formik = useFormik({
    initialValues: {
      email: loginEmail,
      password: password,
    },
    onSubmit: ({ email, password }) => {
      dispatch(login(email, password));
    },
    validationSchema: SignInSchema,
  });

  return (
    <div
      className="p-4 bg-white rounded shadow"
      style={{
        maxWidth: "500px",
        width: "100%",
        margin: "0 auto",
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="m-2">
          <div className="text-center pb-3">
            <img src={Logo} alt="logo" style={{ height: " 48px" }} />
          </div>
          <div className="position-relative pb-3 mb-3">
            <label className="input-element-label">{t("email")}</label>
            <FormikField name="email" className={``} formik={formik} />
          </div>
          <div className="position-relative mb-3">
            <label className="input-element-label">{t("password")}</label>
            <div className="position-relative pb-3">
              <FormikField
                name={"password"}
                className={``}
                formik={formik}
                type={showPsw ? "text" : "password"}
              />
              <span
                className={`password-check ${showPsw ? "show" : ""}`}
                onClick={() => setShowPsw(!showPsw)}
              />
            </div>
          </div>

          <div className="text-center mt-4 position-relative pb-1">
            <button type="submit" className="btn btn-primary px-4">
              {t("enter")}
            </button>
            {message && <span className="text-danger"> {message}</span>}
          </div>
        </div>
      </form>
    </div>
  );
}
