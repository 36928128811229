import React, {useCallback} from "react";
import Modal from "./Modal";
import {FieldArray, FormikProvider, useFormik} from "formik";
import FormikField from "../formComponents/FormikField";
import * as Yup from "yup";
import swal from "sweetalert";
import {useTranslation} from "react-i18next";
import {UseCreateMccApi, UseGetGoogleMiddleMccApi} from "../../redux/api/GoogleMccApi";
import FormikSelect from "../formComponents/FormikSelect";
import {Load} from "../load/Load";

const ModalAddMcc = ({show, closeCallback}) => {
  const {t} = useTranslation();
  const {data, isLoading} = UseGetGoogleMiddleMccApi();
  const [addMcc, {isLoading: isLoadingAddMcc}] = UseCreateMccApi();

  const handleSubmit = (values) => {

    const data = {
      mccIds: values.mccList.map((item) => item.mccItem),
      middleMccId: values.middleMcc.value,
    }

    addMcc(data).then(({error}) => {
      !error && swal({
        icon: "success",
        text: t("MCCAddSuccess"),
      }).then(() => {
        closeCallback()
      })
    })
  }

  const validationSchemaAddMcc = Yup.object().shape({
    middleMcc: Yup.object().nullable()
      .test("testMiddleMcc", t("validation.required"), (value) => {
        return value && value?.value;
      }),
    mccList: Yup.array().of(
      Yup.object().shape({
        mccItem: Yup.string()
          .test(
            "mccItemTest",
            t("validation.onlyNumberAndDash"),
            (value) => {
              let regexp = /^[0-9-]*$/;
              return regexp.test(value);
            }
          )
          .max(50, t("validation.notMore", {number: 50}))
          .min(10, t("validation.noLess", {number: 10}))
          .required(t("validation.required")),
      })
    ),
  });

  const formik = useFormik({
    initialValues: {
      middleMcc: null,
      mccList: [{
        mccItem: "",
      }]
    },
    validationSchema: validationSchemaAddMcc,
    onSubmit: (values) => handleSubmit(values)
  });

  const middleMccOption = useCallback(() => {
    return data?.map((item) => ({
      value: item.id,
      label: item.tagsName,
    }))

  }, [data]);

  if (isLoading) {
    return <Load/>
  }

  return (
    <Modal show={show}
           title={t("AddFreeMCC")}
           notCloseOnOverlay={true}
           closeCallback={closeCallback}>
      <FormikProvider value={formik}>
        <form
          onSubmit={formik.handleSubmit}>
          <div className={"position-relative pb-4"}>
            <FormikSelect
              title={t("clientTag")}
              options={middleMccOption()}
              name={"middleMcc"}
              formik={formik}
              className="pb-3 pl-0 position-relative"
            />
          </div>
          <FieldArray
            name={"mccList"}
            render={(arrayHelpers) => {
              return (
                <div className="">
                  <label>{t("clientMccId")}</label>
                  {formik.values.mccList.map((mccItem, index) => {
                    return (
                      <div key={index}>
                        <div className="d-flex pb-2 position-relative">
                          <div className="d-flex align-items-end">
                            <FormikField
                              className={``}
                              name={`["mccList"][${index}]["mccItem"]`}
                              formik={formik}
                              isArr={true}
                              arr={{fieldType: "mccList", index, fieldName: "mccItem"}}
                            />
                          </div>
                          <div className="d-flex align-items-end">
                            {formik.values.mccList.length !== 1 && (
                              <button
                                className="btn btn-rounded btn-remove mb-3 ml-2"
                                type="button"
                                onClick={() => {
                                  arrayHelpers.remove(index);
                                }}
                              />
                            )}

                            {formik.values.mccList.length === index + 1 && (
                              <button
                                type="button"
                                className="btn btn-rounded btn-add mb-3 ml-2"
                                onClick={() => {
                                  arrayHelpers.push({mccItem: ""});
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>)
                  })
                  }
                </div>
              )
            }}
          />
          <div className={"d-flex align-items-center justify-content-center pt-4"}>
            <button className={"btn btn-primary mr-4 px-4"}
                    disabled={isLoadingAddMcc || isLoading}
                    type={"submit"}>{t("add")}
            </button>
            <button className={"btn btn-outline-primary px-4"}
                    type={"button"}
                    onClick={() => {
                      formik.resetForm();
                      closeCallback();
                    }}
            >{t("cancel")}
            </button>
          </div>
        </form>
      </FormikProvider>
    </Modal>
  )
}

export default ModalAddMcc