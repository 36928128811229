import {getCookie, setCookie} from "../../utils/api";

export const SetCookieVisibleColumn = (name, partValue) => {
  // console.log("name", name, "partValue: ", partValue);
const separator = "__"
  const hiddenColumnString = getCookie(name) || "";
  const hiddenColumnArray = hiddenColumnString ? hiddenColumnString.split(separator) : [];
  const indexPartValue = hiddenColumnArray.indexOf(partValue);
  // console.log("indexPartValue: ", indexPartValue, "hiddenColumnString: ", hiddenColumnString)
  // console.log("hiddenColumnString: ", hiddenColumnString)
  // console.log("hiddenColumnArray: ", hiddenColumnArray)
  let newHiddenColumnString = "";
  // arr.splice(1, 1);
  if (indexPartValue <= -1) {
    newHiddenColumnString = hiddenColumnString !== "" ? hiddenColumnString + separator + partValue : partValue;
  } else {
    hiddenColumnArray.splice(indexPartValue, 1);
    newHiddenColumnString = hiddenColumnArray.join(separator);
    // console.log("innern: ", hiddenColumnArray.splice(indexPartValue, 1).join(separator), hiddenColumnArray)
  }
  setCookie(name, newHiddenColumnString)
  // console.log("newHiddenColumnString", newHiddenColumnString)

};

