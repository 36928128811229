import i18next from "i18next";

export const FinApplicationTypeFormatter = (type)=>{
  switch (type){
    case "REPLENISH":
      return i18next.t("replenishRequestDesc");
    case "MONEY_BACK":
      return i18next.t("moneyBackRequestDesc");
    case "TRANSFER":
      return i18next.t("transferRequestDesc") ;
    default:
      console.log("unknown Application type: ", type);
      return "";
  }
}

export const FinAppStatuses = Object.freeze({
  NEW: "NEW",
  IN_PROCESS:"IN_PROCESS",
  REJECTED:"REJECTED",
  COMPLETED:"COMPLETED"
})

export const FinApplicationsStatusFormatter = (status)=>{
    switch (status){
      case FinAppStatuses.NEW:
        return i18next.t("new")
      case FinAppStatuses.COMPLETED:
        return i18next.t("done");
      case FinAppStatuses.REJECTED:
        return i18next.t("rejected");
      case FinAppStatuses.IN_PROCESS:
        return i18next.t("inProcessing")
      default:
        return "Unknown status";
    }
  }